import React, { useContext } from "react";
import { Badge, Button, Nav, Navbar } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import logo from "../avverktoy.svg";
import { PrivateRoute } from "../components/PrivateRoute";
import { RequireAuth, RequireNotAuth } from "../components/RequireAuth";
import { StateContext } from "../context/AuthContext";
import { Login } from "../views/Login";
import { Logout } from "../views/Logout";
import { ProjectsRouter } from "./ProjectsRouter";

export function MainRouter() {
  const { state } = useContext(StateContext);
  const { authenticatedUser } = state;

  return (
    <Router>
      <div className="d-flex flex-column h-100">
        <Navbar bg="dark" variant="dark" expand="lg" className="px-3">
          <Link to="/">
            <Navbar.Brand>
              <img
                alt=""
                src={logo}
                width="45"
                height="45"
                className="d-inline-block align-middle"
              />
              <span className="ms-1 h4 align-text-top fw-normal">
                {process.env.REACT_APP_TITLE.replace("AV ", "")}
              </span>
            </Navbar.Brand>
          </Link>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Link to="/">
                <Button variant="outline" className="fs-5 text-light">
                  Hjem
                </Button>
              </Link>
              <RequireNotAuth>
                <Link to="/login">
                  <Button variant="outline" className="fs-5 text-light">
                    Logg inn
                  </Button>
                </Link>
              </RequireNotAuth>
              <RequireAuth>
                <Link to="/projects">
                  <Button variant="outline" className="fs-5 text-light">
                    Prosjekt
                  </Button>
                </Link>
                <Link to="/logout">
                  <Button variant="outline" className="fs-5 text-light">
                    Logg ut
                  </Button>
                </Link>
              </RequireAuth>
            </Nav>
            <RequireAuth>
              <Badge text="dark" bg="light" className="p-2">
                {authenticatedUser?.username}
              </Badge>
            </RequireAuth>
          </Navbar.Collapse>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </Navbar>
        <div className="flex-1 flex-grow-1">
          <Switch>
            <Route exact path="/">
              <Redirect to="/projects" />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <PrivateRoute exact path="/logout">
              <Logout />
            </PrivateRoute>
            <PrivateRoute path="/projects" loginRoute="/login">
              <ProjectsRouter />
            </PrivateRoute>
          </Switch>
        </div>
      </div>
    </Router>
  );
}
