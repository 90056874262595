import superagent from "superagent";

export function pathCombine() {
  if (arguments.length === 0) {
    throw new Error("pathCombine called without arguments");
  }

  return Array.from(arguments).reduce((p, c, i) => {
    if (p === "") {
      return c;
    }
    if (p.endsWith("/") && c.startsWith("/")) {
      return `${p.slice(0, -1)}${c}`;
    } else if (
      (p.endsWith("/") && !c.startsWith("/")) ||
      (!p.endsWith("/") && c.startsWith("/"))
    ) {
      return `${p}${c}`;
    } else {
      return `${p}/${c}`;
    }
  }, "");
}

export function apiGet(fragment, query = undefined, onProgress = undefined) {
  const req = superagent.get(
    pathCombine(process.env.REACT_APP_WEB_API, fragment)
  );

  if (query) {
    req.query(query);
  }

  if (onProgress && typeof onProgress === "function") {
    req.on("progress", onProgress).catch((err) => {
      console.error(err);
      return Promise.resolve(err);
    });
  }

  return req;
}

export function apiPost(fragment, data = {}, onProgress = (e) => {}) {
  return superagent
    .post(`${process.env.REACT_APP_WEB_API}${fragment}`, data)
    .on("progress", onProgress)
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export function apiProjectImage(projectId) {
  return `${process.env.REACT_APP_WEB_API}Project/Image/${projectId}`;
}

export function apiProjectImageDownload(projectId) {
  return `${process.env.REACT_APP_WEB_API}Project/Image/Download/${projectId}`;
}

export function apiImage(projectId, recordingId) {
  return `${process.env.REACT_APP_WEB_API}admin/get-project-recording-image/${projectId}/${recordingId}`;
}

const AVTRACE_AUTH_KEY = "avtrace_auth";

export function setAuthenticated(token) {
  return localStorage.setItem(AVTRACE_AUTH_KEY, JSON.stringify(token));
}

export function getAuthenticated() {
  try {
    return JSON.parse(localStorage.getItem(AVTRACE_AUTH_KEY));
  } catch (error) {
    return undefined;
  }
}

export function clearAuthenticated() {
  return localStorage.setItem(AVTRACE_AUTH_KEY, undefined);
}

export function isAuthenticated() {
  var s = getAuthenticated();
  if (!s) return false;
  if (s.username && new Date(s.expires) > new Date()) return true;
}

export function getTrackerSummary(data) {
  if (typeof data.trackerSummary === "object") {
    return data.trackerSummary.totalItemsTracked || 0;
  }
  return "n/a";
}

export function getCounterSummary(data) {
  if (typeof data.counterSummary === "object") {
    return Object.keys(data.counterSummary)
      .map((areaId) => data.counterSummary[areaId]._total)
      .reduce((p, v) => p + v, 0);
  }
  return "n/a";
}
