import { Button, Modal, ModalHeader } from "react-bootstrap";

export function NoData({
  popup = false,
  message = "Ingen data i utvalget",
  resetFunc = undefined,
  children = undefined,
  show = true,
  onHide = undefined,
}) {
  if (show && (popup === false || typeof onHide !== "function")) {
    return <p className="bg-white text-secondary text-center">{message}</p>;
  } else if (show) {
    return (
      <Modal backdrop="static" show={show} onHide={() => onHide()} centered>
        <ModalHeader closeButton></ModalHeader>
        <Modal.Body className="text-center">
          <h3>{message}</h3>
          {children && <p>{children}</p>}
        </Modal.Body>
        {typeof resetFunc === "function" && (
          <Modal.Footer>
            <Button onClick={() => resetFunc()}>Nullstill</Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  } else {
    console.warn("NoData not rendering");
    return null;
  }
}
