import "./SingleProject.css";

import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { useParams } from "react-router";

import { Feature, Map, View } from "ol";
import { defaults as controlDefaults } from "ol/control/defaults";
import { Point } from "ol/geom";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { fromLonLat } from "ol/proj";
import OSM from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import { useContext, useEffect, useMemo, useRef } from "react";
import { Dt } from "../common/DateUtils";
import { SingleProjectContext } from "../routers/SingleProjectRouter";
import { apiProjectImage } from "./../common/Utils";
import { Loading } from "./../components/Loading";

export function SingleProject() {
  const { projectId } = useParams();
  const { filter } = useContext(SingleProjectContext);
  const { project, detectionMeta } = filter;

  const mapRef = useRef();

  const [startTime, endTime, duration] = useMemo(() => {
    var d0 = new Dt(detectionMeta.start_time);
    var d1 = new Dt(detectionMeta.end_time);
    return [
      d0.trunc("second").toISOString(true),
      d1.trunc("second").toISOString(true),
      d1.subtract(d0),
    ];
  }, [detectionMeta]);

  useEffect(() => {
    if (!mapRef.current && project) return;

    var _src = new VectorSource();
    var _lyr = new VectorLayer({
      source: _src,
      properties: {
        title: "Kameralokalisering",
      },
    });

    var _map = new Map({
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      controls: controlDefaults(),
      target: mapRef.current,
      view: new View({
        center: [0, 0],
        zoom: 2,
      }),
    });

    const _ptWebMerc = fromLonLat([project.longitude, project.latitude], "EPSG:3857")
    const _feature = new Feature({geometry: new Point(_ptWebMerc)});
    _src.addFeature(_feature);
    _map.addLayer(_lyr);

    _map.getView().setCenter(_ptWebMerc);
    _map.getView().setZoom(12);

    return () => {
      _map.dispose();
    };
  }, [project]);

  if (!project) {
    return <Loading label="Laster prosjekt" popup />;
  }

  return (
    <Container fluid className="gap-3 p-3">
      <Row className="gap-3">
        <Card as={Col} className="p-3">
          <Card.Img variant="top" src={apiProjectImage(projectId)} />
          <Card.Body>
            <Card.Text>{project.description}</Card.Text>
          </Card.Body>
        </Card>
        <Card as={Col} className="d-flex p-3">
          <div ref={mapRef} className="flex-fill"></div>
        </Card>
        <Card as={Col}>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Property</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Start time</td>
                  <td>{startTime}</td>
                </tr>
                <tr>
                  <td>End</td>
                  <td>{endTime}</td>
                </tr>
                <tr>
                  <td>Duration</td>
                  <td>{duration}</td>
                </tr>
                <tr>
                  <td>Image width x height</td>
                  <td>{project.image_width} x {project.image_height}</td>
                </tr>
                <tr>
                  <td>Detection width x height</td>
                  <td>{project.detection_width} x {project.detection_height}</td>
                </tr>
                <tr>
                  <td>Detection timezone</td>
                  <td>{project.detection_timezone}</td>
                </tr>
                <tr>
                  <td>Display timezone</td>
                  <td>{project.display_timezone}</td>
                </tr>
                <tr>
                  <td>Precipitation sensor</td>
                  <td>{project.precipitation_sensor}</td>
                </tr>
                <tr>
                  <td>Air temperature sensor</td>
                  <td>{project.temperature_sensor}</td>
                </tr>
                <tr>
                  <td>Location</td>
                  <td>
                    {project.longitude} / {project.latitude}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}

export default SingleProject;
