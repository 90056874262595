import "./ProjectList.scss";

import { useEffect, useState } from "react";
import { Button, Card, Container, Row } from "react-bootstrap";
import { FaChartBar } from "react-icons/fa";

import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { apiGet } from "../common/Utils";
import { Dialog } from "../components/Dialog";
import { PageTitle } from "../components/PageTitle";
import { ProjectForm } from "../components/ProjectForm";
import { useContext } from "react";
import { StateContext } from "../context/AuthContext";

export function ProjectList() {
  const [projects, setProjects] = useState([]);
  const [editProject, setEditProject] = useState(-1);
  const [reload, setReload] = useState(false);
  const { url } = useRouteMatch();
  const { state } = useContext(StateContext);

  useEffect(() => {
    const username = state.authenticatedUser?.username ?? null;

    if (!username) return null;

    apiGet(`Project/List/${username}`)
      .then((res, err) => {
        if (res.ok) {
          setProjects(res.body);
          setReload(false);
          return;
        }
        throw new Error(res.body);
      })
      .catch((err) => {
        toast.error(
          `Det oppstod ein feil ved lasting av prosjekter (${err.message})`
        );
      });
  }, [reload, state]);

  return (
    <Container className="mt-3">
      <Card bg="primary" className="p-3">
        <Card.Title>
          <PageTitle subpage="Prosjekt" />
        </Card.Title>
        <Card.Body className="fs-6">
          På denne siden kan du opprette, redigere eller velge hvilke prosjekter
          du ønsker å arbeide med. Et prosjekt representerer en scene fra enten
          et kamera eller en videofil.
        </Card.Body>
      </Card>
      <Container fluid className="mt-3">
        <Row>
          <div className="h4">Prosjektliste</div>
        </Row>
        <Row>
          <table className="striped-table mt-2 fs-6">
            <thead>
              <tr>
                <th>#</th>
                <th>Prosjektnavn</th>
                <th>Åpne dashboard</th>
              </tr>
            </thead>
            <tbody>
              {projects.length > 0 &&
                projects.map((p, i) => {
                  return (
                    <tr key={`project-row-${i}`}>
                      <td className="align-middle">{p.id}</td>
                      <td className="align-middle fs-5">
                        <Link className="text-dark" to={`${url}/${p.id}`}>{p.title}</Link>
                      </td>
                      <td>
                        <Link to={`${url}/${p.id}/tracker`}>
                          <Button variant="outline-dark" className="border-0">
                            <FaChartBar /> Vis spor
                          </Button>
                        </Link>
                        <Link to={`${url}/${p.id}/heatmap`}>
                          <Button variant="outline-dark" className="border-0">
                            <FaChartBar /> Vis heatmap
                          </Button>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </Row>
      </Container>
      <Dialog
        show={!isNaN(editProject) && editProject > -1}
        title="Rediger prosjekt"
        onClose={() => {
          setEditProject(-1);
          setReload(true);
        }}
      >
        <ProjectForm
          projectId={editProject}
          onSuccess={() => {
            setEditProject(-1);
            setReload(true);
          }}
          onError={(err) => {
            toast.error(err);
          }}
        />
      </Dialog>
    </Container>
  );
}

export default ProjectList;
