import { Nav, Navbar } from "react-bootstrap";

import { NavLink } from "react-router-dom";
import { useContext } from "react";
import { SingleProjectContext } from "../routers/SingleProjectRouter";

export function SingleProjectNavBar() {
  const { filter } = useContext(SingleProjectContext);
  const { project } = filter;

  if (!project) return null;

  return (
    <>
      <Navbar bg="primary" className="px-3">
        <Navbar.Brand href="#home">
          <div className="lead-4">Prosjekt: {project.title}</div>
        </Navbar.Brand>
        <Nav className="ms-auto">
          <NavLink to={`/projects/${project.id}`} className="px-3 text-dark">
            Info
          </NavLink>
          <NavLink to={`/projects/${project.id}/tracker`} className="px-3 text-dark">
            Sporing
          </NavLink>
          <NavLink to={`/projects/${project.id}/heatmap`} className="px-3 text-dark">
            Heatmap
          </NavLink>
          <NavLink to={`/projects/${project.id}/download`} className="px-3 text-dark">
            Data
          </NavLink>
        </Nav>
      </Navbar>
    </>
  );
}
