import "dc/dc.css";
import "ol/ol.css";
import "./HeatMap.css";

import React, { useEffect, useMemo, useState } from "react";

import moment from "moment-timezone";
import { useCallback, useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import { useParams } from "react-router";
import { downloadObjectAsCsv } from "../common/DownloadUtils";
import { apiGet } from "../common/Utils";
import Loading from "../components/Loading";
import { SingleProjectContext } from "../routers/SingleProjectRouter";
import { csvFormat } from "d3";

export function Download() {
  const { projectId } = useParams();
  const [loading, setLoading] = useState(undefined);
  const { filter } = useContext(SingleProjectContext);
  const { project } = filter;
  const { start_time, end_time, detection_timezone } = project;

  const [resolution, setResolution] = useState("1M");
  const [source, setSource] = useState("SummaryCached");

  const downloadHandler = useCallback((ds, de) => async () => {
    try {
      setLoading(true);
      let res = await apiGet(`Detection/${source}`, {
        projectId,
        fromDate: moment(ds).format("YYYY-MM-DD"),
        toDate: moment(de).format("YYYY-MM-DD"),
        detectionTimeZone: detection_timezone,
      });
      const csvStr = csvFormat(res.body);
      downloadObjectAsCsv(csvStr, `${ds.toISOString()}.csv`);
    } catch (error) {
      console.warn(error);
    } finally {
      setLoading(false);
    }
  }, [detection_timezone, projectId, source]);

  const incrementDate = useCallback((dt)=>{
    if (resolution === "7D") {
      dt.setDate(dt.getDate() + 7);
    } else if (resolution === "3D") {
      dt.setDate(dt.getDate() + 3);
    } else if (resolution === "1D") {
      dt.setDate(dt.getDate() + 1);
    } else if (resolution === "6M") {
      dt.setMonth(dt.getMonth() + 6);
    } else if (resolution === "3M") {
      dt.setMonth(dt.getMonth() + 3);
    } else {
      dt.setMonth(dt.getMonth() + 1);
    }
  },[resolution]);

  const months = useMemo(() => {
    let startTime = moment(start_time);
    let endTime = moment(end_time);
    if (!startTime || !endTime) return [];
    const d1 = new Date(startTime);
    d1.setDate(1);
    d1.setHours(0, 0, 0, 0, 0);
    const d2 = new Date(endTime);    
    d2.setDate(1);
    d2.setHours(0, 0, 0, 0);
    incrementDate(d2);
    let ranges = [];
    while (d1 < d2) {
      let ds = new Date(d1);
      let de = new Date(d1);
      incrementDate(de);
      ranges.push(
        <Col key={`date-range-${d1.toISOString()}`} sm={3} className="p-1">
          <Card>
            <CardBody>
              {moment(d1).format("DD. MMM yyyy")}—
              {moment(de).format("DD. MMM yyyy")}
            </CardBody>
            <CardFooter className="d-flex">
              <Button className="ms-auto" onClick={downloadHandler(ds, de)}>
                <i className="bi bi-floppy"></i> Download (*.csv)
              </Button>
            </CardFooter>
          </Card>
        </Col>
      );
      incrementDate(d1);
    }
    return ranges;
  }, [start_time, end_time, downloadHandler, incrementDate]);

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <>
      <Navbar bg="white" className="px-1">
        <Nav className="ms-auto">
          <Nav.Link active={source==="Summary"} onClick={() => setResolution("Summary")}>Sanntid</Nav.Link>
          <Nav.Link active={source==="SummaryCached"} onClick={() => setSource("SummaryCached")}>Cache</Nav.Link>
          <Nav.Link>&bull;</Nav.Link>
          <Nav.Link active={resolution === "1D"} onClick={() => setResolution("1D")}>1 dag</Nav.Link>
          <Nav.Link active={resolution === "3D"} onClick={() => setResolution("3D")}>3 dager</Nav.Link>
          <Nav.Link active={resolution === "7D"} onClick={() => setResolution("7D")}>7 dager</Nav.Link>
          <Nav.Link active={resolution === "1M"} onClick={() => setResolution("1M")}>1 måned</Nav.Link>
          <Nav.Link active={resolution === "3M"} onClick={() => setResolution("3M")}>3 måneder</Nav.Link>
          <Nav.Link active={resolution === "6M"} onClick={() => setResolution("6M")}>6 måneder</Nav.Link>
        </Nav>
      </Navbar>
      <Container>
        <Row>{months}</Row>
      </Container>
      {loading && <Loading label="Preparing download" popup />}
    </>
  );
}

export default Download;
