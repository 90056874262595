import {scaleOrdinal, schemeCategory10} from "d3";
import dc from "dc";

dc.config.defaultColors(["#ff0000", "#00ff00", "#0000ff"]);

export const objClassColors = scaleOrdinal()
  .domain(["p", "person", "sykkel", "varebil", "lastebil"])
  .range(["#0099CC", "#0099CC", "#2200CC", "#FF9900", "#FF0000"]);

export const colors = scaleOrdinal().range(schemeCategory10);
