import moment from "moment-timezone";
import React, { useState, useEffect } from "react";
import { Switch, useParams, useRouteMatch } from "react-router-dom";
import { PrivateRoute } from "../components/PrivateRoute";
import { SingleProject } from "../views/SingleProject";
import { SingleProjectNavBar } from "../views/SingleProjectNavBar";
import { apiGet } from "../common/Utils";
import { HeatMap } from "../views/HeatMap";
import { Tracker } from "../views/Tracker";
// // import Loading from "../components/Loading";
// // import { NoData } from "../components/NoData";
// import { Dt } from "../common/DateUtils";
import { createContext } from "react";
import Download from "../views/Download";

export const SingleProjectContext = createContext({});

export function SingleProjectContextProvider({ children, projectId }) {
  const [filter, setFilter] = useState();

  useEffect(() => {
    if (!projectId) return;
    Promise.all([
      apiGet(`Project/Get/${projectId}`),
      apiGet(`Detection/Metadata/${projectId}`),
    ]).then((results) => {
      const [projectData, detectionMeta] = results;
      setFilter((filter) => {
        let _filter = {
          ...filter,
        };

        if (
          projectData.ok &&
          Array.isArray(projectData.body) &&
          projectData.body.length > 0
        ) {
          _filter.project = projectData.body[0];
          _filter.noData = false;
        } else {
          _filter.project = null;
          _filter.noData = true;
        }

        if (
          detectionMeta.ok &&
          Array.isArray(detectionMeta.body) &&
          detectionMeta.body.length > 0
        ) {
          // Store detection metadata
          _filter.detectionMeta = detectionMeta.body[0];

          // Convert time zones
          // Earliest deteection timestamp
          _filter.detectionMeta.start_time = moment(
            _filter.detectionMeta.start_time
          )
            .tz(_filter.project.detection_timezone, true)
            .tz(_filter.project.display_timezone);

          // Latest detection timestamp
          _filter.detectionMeta.end_time = moment(
            _filter.detectionMeta.end_time
          )
            .tz(_filter.project.detection_timezone, true)
            .tz(_filter.project.display_timezone);

          _filter.noData = false;
          _filter.timeRes = _filter.detectionMeta.time_res ?? "15T";
          _filter.startTime = _filter.detectionMeta.start_time
            .startOf("hour")
            .format("YYYY-MM-DDTHH:mm:ss");

          _filter.endTime = _filter.detectionMeta.start_time
            .startOf("hour")
            .add(6, "hours")
            .format("YYYY-MM-DDTHH:mm:ss");
          _filter.valueProp = "detection_nunique";
        } else {
          _filter.detectionMeta = null;
          _filter.noData = true;
        }
        return _filter;
      });
    });
  }, [projectId, setFilter]);

  return (
    <SingleProjectContext.Provider
      value={{
        filter,
        setFilter,
      }}
    >
      {filter && children}
    </SingleProjectContext.Provider>
  );
}

export function SingleProjectRouter() {
  const { path } = useRouteMatch();
  const { projectId } = useParams();

  return (
    <SingleProjectContextProvider projectId={projectId}>
      <SingleProjectNavBar />
      <Switch>
        <PrivateRoute path={`${path}/tracker`}>
          <Tracker />
        </PrivateRoute>
        <PrivateRoute path={`${path}/heatmap`}>
          <HeatMap />
        </PrivateRoute>
        <PrivateRoute path={`${path}/download`}>
          <Download />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}`}>
          <SingleProject />
        </PrivateRoute>
      </Switch>
    </SingleProjectContextProvider>
  );
}
